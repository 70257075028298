
  export default {
    props: {
      html: {
        type: Object,
        default: () => [],
        required: false,
      },
    },
  };
